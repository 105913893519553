import Logo from "../vectors/Logo";

function Footer() {
    return (
        <>
            <footer className="mt-auto w-full flex flex-col md:flex-row justify-between gap-8 py-12 max-sm:items-center max-sm:text-center md:px-32">
                <div className="flex flex-col gap-4">
                    <h1 className="poppins-bold text-xl">Download App</h1>
                    <img src="/google-play-badge.png" className="w-48"></img>
                    <img src="/App-Store-Badge.svg" className="w-48"></img>
                </div>

                <div className="flex flex-col">
                    <h1 className="poppins-bold text-xl">Learn More</h1>
                    <div className="flex flex-col grow flex-1 mt-4 justify-around">
                        <a className="poppins-regular">Home</a>
                        <a className="poppins-regular">About</a>
                        <a className="poppins-regular">FAQ</a>
                        <a className="poppins-regular">Policies</a>
                        <a className="poppins-regular">Contact</a>
                    </div>
                </div>

                <div className="flex flex-col">
                    <h1 className="poppins-bold text-xl">Policies</h1>
                    <div className="flex flex-col grow flex-1 mt-4 justify-around">
                        <a className="poppins-regular">Privacy</a>
                        <a className="poppins-regular">Cancellation</a>
                        <a className="poppins-regular">Refunds</a>
                        <a className="poppins-regular">Terms</a>
                    </div>
                </div>

                <Logo width={250}></Logo>
            </footer>
        </>
    )
}

export default Footer;