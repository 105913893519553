import React, { useState } from 'react';
import Logo from "../vectors/Logo";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(o => !o);
    };

    return (
        <div className="w-full p-8 grid grid-cols-3 md:flex justify-center md:justify-between items-center" id="navbar">
            <div className="md:hidden">
                <button onClick={toggleMenu}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                </button>
            </div>
            <Logo />
            <div className={
                `
                hamburger-menu 
                max-sm:absolute 
                max-sm:top-0 
                max-sm:left-0 
                max-sm:z-50 
                max-sm:h-screen 
                max-sm:px-12
                max-sm:py-16
                max-sm:bg-neutral 
                max-sm:shadow-xl
                ${isOpen ? "translate-x-0" : "translate-x-[-100%]"} 


                md:translate-x-0 
                flex 
                flex-col 
                md:flex-row 
                items-center 
                md:space-x-4`
            }>
                <ul className="flex flex-col max-sm:space-y-4 md:flex-row md:space-x-4 items-center justify-center">
                    <li className="md:hidden list-none w-full flex items-center justify-center" role="button" onClick={toggleMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </li>
                    <li className="list-none">
                        <a className="hover:text-primary-100 transition-colors" href="/#/">
                            Home
                        </a>
                    </li>
                    <li className="list-none">
                        <a className="hover:text-primary-100 transition-colors" href="/#/about">
                            About
                        </a>
                    </li>
                    <li className="list-none">
                        <a className="hover:text-primary-100 transition-colors" href="/#/FAQ">FAQ</a>
                    </li>
                    {/* <li className="list-none">Policies</li> */}
                    <li className="list-none mx-4 max-sm:hidden">
                        <button className="bg-primary-100 shadow-xl px-4 py-2 text-neutral rounded-3xl">
                            Contact
                        </button>
                    </li>
                </ul>
            </div>

            <div className="md:hidden flex w-full items-center justify-end">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                </svg>
            </div>


        </div>
    )
}

export default Navbar;
