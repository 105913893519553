import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useRef, useState } from 'react';
import './App.css';
import LargeButton from './Components/LargeButton';
import Navbar from './Components/Navbar';

function About() {
    const [storyExpanded, setStoryExpanded] = useState(false)
    return (
        <>
            <div className="flex flex-col min-h-screen w-full bg-neutral">
                <Navbar />

                <div className="flex flex-col gap-4 flex-1 grow items-center justify-center px-4 md:px-24 lg:px-32">
                    <h1 className="text-4xl text-center font-bold">Our Story</h1>
                    <p className="text-lg text-center">
                        In the heart of Cairo, the founders of Wasalny experienced firsthand the challenges of navigating the country’s complex transportation system. They felt the frustration that was shared by many, as the chaotic nature of the system made it difficult for everyone to find suitable transportation methods.
                    </p>

                    {storyExpanded &&
                        <>
                            <p className="text-lg text-center">
                                Driven by a desire to make a difference, they embarked on a journey of discovery. They spent months conducting research and interviewing fellow citizens, seeking to understand the root of the problem. What they found was enlightening - the issue was not with the transportation services themselves, but rather with the difficulty of accessing them and determining which one best suited an individual’s needs.
                            </p>
                            <p className="text-lg text-center">
                                Inspired by this revelation, the founders of Wasalny set out on a mission to create a solution. They envisioned a platform that would act as a guiding light for all Egyptians, shedding light on the vast array of transportation choices at their disposal. This platform would not only provide clear visibility of all possible transportation methods but also empower individuals to effectively compare their options and make informed decisions.
                            </p>
                            <p className="text-lg text-center">
                                Today, Wasalny stands as a testament to this vision - a go-to platform that simplifies the process of finding transportation in Egypt, ensuring that everyone can choose the most suitable option for them. It’s more than just a service; it’s a commitment to improving the lives of every Egyptian, one journey at a time.
                            </p>
                        </>
                    }

                    <a className="z-20 text-primary-200 font-bold text-lg" role="button" onClick={() => setStoryExpanded(s => !s)}>
                        {
                            !storyExpanded && "Read More"
                        }

                        {
                            storyExpanded && "Collapse"
                        }
                    </a>
                </div>

                <div className="z-0 flex flex-col h-32 px-5 md:px-24 lg:px-32 mt-32 w-full bg-primary-400 ">
                    <div className="flex md:h-full justify-between w-full translate-y-[-75%]">
                        <img src="/Bus-Illustration.svg" className="max-sm:h-12" />
                        <img src="/bus-stop.svg" className="max-sm:h-12 max-sm:w-auto md:w-24 lg:w-32" />
                    </div>

                </div>
            </div>

            <div className="flex flex-col w-full py-32">
                <div className="lg:w-8/12 lg:px-32 px-4 text-center lg:text-start">
                    <h1 className="text-primary-100 font-bold text-5xl">Our Vision</h1>
                    <p className="font-bold mt-2 text-lg">Envisioning a future of accessible transportation options and congestion-free cities</p>
                    <p className="text-lg">
                        We envision a future where cities are free-flowing and interconnected, free from the constraints of congestion, where accessibility is right to every citizen, not a luxury. We aim to eliminate the need for personal car ownership, fostering a more sustainable and connected world. This is our commitment, our promise, and our vision for the future.
                    </p>

                    <p className="text-xl mt-2 font-bold text-primary-100">Together, we can make this vision a reality</p>
                </div>
                <div className="px-5 lg:px-24 md:mt-0 lg:mt-[-10%] max-sm:hidden">
                    <img src="/infographic-speech.svg" className="w-full" />
                </div>
            </div>

            <div className="flex flex-col lg:flex-row items-center justify-center w-full px-4 md:px-24 lg:px-32 py-28 bg-dark text-neutral">
                <h1 className="text-5xl font-bold">Our Promise</h1>

                <div className="lg:ps-32 mt-5 lg:mt-0 flex-1 grow text-left items-center justify-center">
                    <p className="text-center lg:text-start">
                        We are committed to providing you with comprehensive and unbiased information about all available transportation options. Our goal is to empower you to make informed decisions that best suit your travel needs. We believe in transparency and trust, and we assure you that our recommendations are always in your best interest. With Wasalny, you have a reliable partner that supports you in navigating the complexities of transportation choices. Trust us to guide you on your journey, every step of the way
                    </p>
                </div>
            </div>

            <div className="flex flex-col items-center w-full py-28 px-5 lg:px-32">
                <div className="w-full text-center lg:text-start">
                    <h1 className="text-5xl font-bold text-primary-100">Our Mission</h1>
                    <p className="text-lg">Wasalny Operates to Empower You</p>
                </div>

                <img className="mt-12 lg:mt-0" src="/infographic.svg" />
            </div>

            <div className="flex flex-col px-5 lg:px-32 w-full">
                <div className="flex flex-col lg:flex-row text-center lg:text-start w-full">
                    <div className="flex-1 grow text-4xl font-bold">Wasalny Leadership</div>
                    <div className="flex-1 grow text-lg max-sm:mt-5">
                        Wasalny was founded by Ali Amer, Mohamed Sameh, Omar Hadidy, Omar Tamer, and Yassin Tamer in an effort to elevate the region’s mobility solutions.
                    </div>
                </div>


                <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 mt-5">
                    <div className="text-center">
                        <div className="relative" style={{ paddingBottom: '100%' }}>
                            <div className="absolute inset-0 rounded-3xl bg-primary-100"></div>
                        </div>
                        <p className="font-bold mt-3">Ali Amer</p>
                        <p className="mt-1">Co-Founder</p>
                        <p>CPO</p>
                    </div>


                    <div className="text-center">
                        <div className="relative" style={{ paddingBottom: '100%' }}>
                            <div className="absolute inset-0 rounded-3xl bg-primary-100"></div>
                        </div>

                        <p className="font-bold mt-3">Mohamed Sameh</p>
                        <p className="mt-1">Co-Founder</p>
                        <p>CMO</p>
                    </div>

                    <div className="text-center">
                        <div className="relative" style={{ paddingBottom: '100%' }}>
                            <div className="absolute inset-0 rounded-3xl bg-primary-100"></div>
                        </div>

                        <p className="font-bold mt-3">Omar El Hadidy</p>
                        <p className="mt-1">Co-Founder</p>
                        <p>CIO</p>
                    </div>

                    <div className="text-center">
                        <div className="relative" style={{ paddingBottom: '100%' }}>
                            <div className="absolute inset-0 rounded-3xl bg-primary-100"></div>
                        </div>

                        <p className="font-bold mt-3">Omar Tamer</p>
                        <p className="mt-1">Co-Founder</p>
                        <p>CTO</p>
                    </div>

                    <div className="text-center">
                        <div className="relative" style={{ paddingBottom: '100%' }}>
                            <div className="absolute inset-0 rounded-3xl bg-primary-100"></div>
                        </div>

                        <p className="font-bold mt-3">Yassin Tamer</p>
                        <p className="mt-1">Co-Founder</p>
                        <p>CEO</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;