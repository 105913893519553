import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import LargeButton from './Components/LargeButton';
import Navbar from './Components/Navbar';

function Accordion({ title, content }) {
    const [collapsed, setCollapsed] = useState(true);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [collapsed]);

    return (
        <div role="button" onClick={() => setCollapsed(c => !c)} className="overflow-hidden w-full flex flex-col py-8 border border-gray my-3 rounded-xl">
            <div className="flex px-5">
                <div className="flex-1 grow font-semibold">
                    {title}
                </div>

                <div className="px-5">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${collapsed ? '' : 'rotate-180'} transition-transform duration-200 ease-in-out`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </div>
            </div>

            <div
                className="transition-max-height overflow-hidden duration-200 ease-in-out"
                style={{ maxHeight: collapsed ? 0 : `${contentHeight}px` }}
            >
                <div ref={contentRef} className="px-5">
                    <p>
                        {content}
                    </p>
                </div>
            </div>
        </div>
    );
}



function FAQ() {
    const [storyExpanded, setStoryExpanded] = useState(false)
    return (
        <>
            <div className="flex flex-col min-h-screen w-full bg-neutral">
                <Navbar />

                <div className="flex flex-col flex-1 grow w-full items-center justify-center">
                    <h1 className="text-5xl text-center lg:text-start text-primary-100 font-bold">
                        Wasalny FAQs: Navigating Your Journeys
                    </h1>

                    <h3 className="text-lg text-center lg:text-start text-dark mt-3">
                        Answers for Smoother Travels and Seamless Connections
                    </h3>

                    <div className="w-full px-5 md:px-24 lg:px-32">
                        <Accordion title={"FAQ Example 1"} content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."} />
                        <Accordion title={"FAQ Example 2"} content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."} />
                        <Accordion title={"FAQ Example 3"} content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."} />
                        <Accordion title={"FAQ Example 4"} content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQ;