import * as React from "react";
const Logo = (props) => {
  return (
    <>
      <div >

        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="185 416.31 630 167.38"
          className="md:h-[40px]"
          {...props}
        >
          <defs>
            <style>{".cls-1{fill:#45474b;}"}</style>
          </defs>
          <path
            className="cls-1"
            d="M323.65,447.38l-27.06,92.55H271.37l-16.88-64.65-16.87,64.65H212.23L185,447.38h23.72l16.37,70.49,17.71-70.49h24.72l17.38,70.33,16.37-70.33Z"
          />
          <path
            className="cls-1"
            d="M324.48,468.43a41.05,41.05,0,0,1,15.2-16.71,40.48,40.48,0,0,1,21.47-5.85,37.73,37.73,0,0,1,18.12,4.18,36.79,36.79,0,0,1,12.45,10.53v-13.2h23.55v92.55H391.72V526.39a34.78,34.78,0,0,1-12.45,10.78A38.05,38.05,0,0,1,361,541.43a39.44,39.44,0,0,1-21.3-6,42.15,42.15,0,0,1-15.2-17,54.46,54.46,0,0,1-5.6-25.14A53.56,53.56,0,0,1,324.48,468.43Zm63.9,10.6a23.62,23.62,0,0,0-9-9.35,24.14,24.14,0,0,0-12.2-3.26,23.52,23.52,0,0,0-21,12.45,29,29,0,0,0-3.43,14.45,29.88,29.88,0,0,0,3.43,14.61,24.64,24.64,0,0,0,9,9.61,22.88,22.88,0,0,0,11.94,3.34,24,24,0,0,0,12.2-3.26,23.56,23.56,0,0,0,9-9.35,33.67,33.67,0,0,0,0-29.24Z"
          />
          <path
            className="cls-1"
            d="M439.83,537.34a36.12,36.12,0,0,1-14.28-11.11,28.21,28.21,0,0,1-5.77-15.54h23.56a12.82,12.82,0,0,0,5.26,8.86,18.35,18.35,0,0,0,11.44,3.5q6.69,0,10.44-2.67a8.07,8.07,0,0,0,3.76-6.85q0-4.51-4.59-6.76A85.69,85.69,0,0,0,455,501.84a123.75,123.75,0,0,1-16.95-5.18,29.34,29.34,0,0,1-11.36-8.19Q422,483,422,473.61a24,24,0,0,1,4.42-14,29.75,29.75,0,0,1,12.7-10,47.58,47.58,0,0,1,19.46-3.68q16.54,0,26.39,8.27t10.86,22.31H473.41a12.17,12.17,0,0,0-4.6-8.78q-4.1-3.26-10.94-3.25-6.35,0-9.77,2.34a7.46,7.46,0,0,0-3.43,6.51q0,4.68,4.68,7.1t14.54,4.93a123.33,123.33,0,0,1,16.53,5.18,29.15,29.15,0,0,1,11.28,8.27q4.76,5.6,4.93,14.78a24.52,24.52,0,0,1-4.43,14.37,29.21,29.21,0,0,1-12.69,9.94,48.23,48.23,0,0,1-19.3,3.59A48.93,48.93,0,0,1,439.83,537.34Z"
          />
          <path
            className="cls-1"
            d="M504.76,468.43A41.05,41.05,0,0,1,520,451.72a40.48,40.48,0,0,1,21.47-5.85,37.73,37.73,0,0,1,18.12,4.18A36.79,36.79,0,0,1,572,460.58v-13.2h23.55v92.55H572V526.39a34.78,34.78,0,0,1-12.45,10.78,38.05,38.05,0,0,1-18.29,4.26,39.44,39.44,0,0,1-21.3-6,42.15,42.15,0,0,1-15.2-17,54.46,54.46,0,0,1-5.6-25.14A53.56,53.56,0,0,1,504.76,468.43Zm63.9,10.6a23.62,23.62,0,0,0-9-9.35,24.14,24.14,0,0,0-12.2-3.26,23.49,23.49,0,0,0-21,12.45,29,29,0,0,0-3.43,14.45,29.88,29.88,0,0,0,3.43,14.61,24.64,24.64,0,0,0,9,9.61,22.88,22.88,0,0,0,11.94,3.34,24,24,0,0,0,12.2-3.26,23.56,23.56,0,0,0,9-9.35,33.67,33.67,0,0,0,0-29.24Z"
          />
          <path className="cls-1" d="M625.76,416.31V539.93H602.37V416.31Z" />
          <path
            className="cls-1"
            d="M710.76,456.48q10.18,10.44,10.19,29.15v54.3H697.56V488.81q0-11-5.51-17t-15-5.93q-9.69,0-15.28,5.93t-5.6,17v51.12H632.75V447.38h23.38V458.9a32.34,32.34,0,0,1,12-9.43A36.83,36.83,0,0,1,684,446Q700.58,446,710.76,456.48Z"
          />
          <path
            className="cls-1"
            d="M815,447.38,757.7,583.69H732.81l20.05-46.1-37.09-90.21H742L765.89,512l24.22-64.65Z"
          />
        </svg>

      </div>

    </>
  )
}

export default Logo;
