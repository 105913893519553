import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import About from "./About";
import './App.css';
import Footer from './Components/Footer';
import FAQ from "./FAQ";
import HomePage from './HomePage';

gsap.registerPlugin(ScrollTrigger);

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/FAQ" element={<FAQ />} />
          {/* <Route path="/contact" element={<ContactPage />} /> */}
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
